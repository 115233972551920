import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";
import Pricing from "../components/Pricing";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import FullWidthImage from "../components/FullWidthImage";
import { Container, Nav, Card, CardImg, CardProps, CardImgProps, Accordion, Collapse } from "react-bootstrap"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import "../styles/custom.css"
import styled from "styled-components"

const StyledHome = styled.div`
  display: flex;
  gap: 0rem;
  position: absolute;
  flex-direction: row;
  margin: 0 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
`;
const Page1 = ({ }) => (
  <Layout>
    <Container>
      <div className="column">
        <div class="text-center"><StyledHome></StyledHome>
          <StaticImage
            src="../img/bg2.jpg"
            width={1920}
            quality={50}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt=""
            style={{ margin: `0`, position: "center", transform: "translateY(-1%)" }}
            layout="constrained"
          />
        </div>

        <div class="text-center">
          <StaticImage
            src="../img/pertum.png"
            width={790}
            quality={50}
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            alt=""
            style={{ margin: `0`, position: "absolute", transform: "translateY(-110%) translateX(-50%)" }}
          />
        </div>

        <h1 class="text-center">КАТАЛОГ СЕЛЬСКОХОЗЯЙСТВЕННОЙ ТЕХНИКИ «ОЗДОКЕН»</h1>
        <Accordion defaultActiveKey={['0']}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>СЕЯЛКИ</Accordion.Header>
            <Accordion.Body>
              <Accordion defaultActiveKey={['0']}>
                <Accordion.Item eventKey="10">
                  <Accordion.Header>ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ</Accordion.Header>
                  <Accordion.Body>
                    <div class="row">
                      <div class="col-sm-6 col-lg-4 mt-4"> <div class="card border-dark h-100"> <div class="card-body border-dark">
                        <StaticImage src="../img/acmk.png"
                          width={392} quality={50} formats={["auto", "webp", "avif"]}
                          alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="/pertum-mekhanicheskaya-seyalka-dlya-zernovyh-kultur"
                          class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                            СЕРИЯ PERTUM МЕХАНИЧЕСКАЯ СЕЯЛКА ДЛЯ ЗЕРНОВЫХ КУЛЬТУР
                          </h5></Link> </div> </div> </div>

                      <div class="col-sm-6 col-lg-4 mt-4"> <div class="card border-dark h-100"> <div class="card-body border-dark">
                        <StaticImage src="../img/acmk.png"
                          width={392} quality={50} formats={["auto", "webp", "avif"]}
                          alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. MILLENYUM  ДЛЯ ПОСЕВА ЗЕРНА  БЕЗ ОБРАБОТКИ ПОЧВЫ.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="/millenyum-dlya-poseva-zerna-bez-obrabotki-pochvy.html"
                          class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                            MILLENYUM  ДЛЯ ПОСЕВА ЗЕРНА  БЕЗ ОБРАБОТКИ ПОЧВЫ
                          </h5></Link> </div> </div> </div>

                      <div class="col-sm-6 col-lg-4 mt-4"> <div class="card border-dark h-100"> <div class="card-body border-dark">
                        <StaticImage src="../img/acmk.png"
                          width={392} quality={50} formats={["auto", "webp", "avif"]}
                          alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. HBM МЕХАНИЧЕСКАЯ  ЗЕРНОВАЯ СЕЯЛКА.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="/hbm-mekhanicheskaya-zernovaya-seyalka.html"
                          class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                            HBM МЕХАНИЧЕСКАЯ  ЗЕРНОВАЯ СЕЯЛКА
                          </h5></Link> </div> </div> </div>

                      <div class="col-sm-6 col-lg-4 mt-4"> <div class="card border-dark h-100"> <div class="card-body border-dark">
                        <StaticImage src="../img/acmk.png"
                          width={392} quality={50} formats={["auto", "webp", "avif"]}
                          alt="ЗЕРНОВЫЕ МЕХАНИЧЕСКИЕ СЕЯЛКИ ОЗДОКЕН. HBM-BABA МЕХАНИЧЕСКАЯ  ЗЕРНОВАЯ СЕЯЛКА.
" style={{ marginBottom: `1.45rem` }} />
                        <Link to="/hbm-baba-mekhanicheskaya-zernovaya-seyalka.html"
                          class="stretched-link link-dark text-decoration-none text-reset"><h5 class="card-title">
                            HBM-BABA МЕХАНИЧЕСКАЯ  ЗЕРНОВАЯ СЕЯЛКА
                          </h5></Link> </div> </div> </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="11">
                  <Accordion.Header>ПРОПАШНЫЕ СЕЯЛКИ АНКЕРНОГО ТИПА</Accordion.Header>
                  <Accordion.Body>

                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="12">
                  <Accordion.Header>ПРОПАШНЫЕ СЕЯЛКИ ДИСКОВОГО ТИПА </Accordion.Header>
                  <Accordion.Body>

                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>КАТКИ</Accordion.Header>
            <Accordion.Body>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>БОРОНЫ</Accordion.Header>
            <Accordion.Body>

            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>КУЛЬТИВАТОРЫ</Accordion.Header>
            <Accordion.Body>

            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

      </div>
    </Container>
  </Layout>
)

export default Page1
